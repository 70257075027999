<template>
  <div class="card border-0 bg-primary mb-md-2 mb-lg-0">
    <div class="card-header bg-transparent">
      <h5 class="text-transparent-white-8 mb-0">
        {{ getReportTitle(data.name) }}
      </h5>
    </div>
    <div class="card-body">
      <!-- Earning Data -->
      <h5 class="text-white">
        {{ totalEarning | moneyFormat }}
      </h5>
      <!-- ./Earning Data -->

      <!-- Earning Rate -->
      <p class="text-transparent-white-7 font-13">
        <template v-if="showPercent">
          <b class="ml-1" v-tooltip.bottom="data.last_earning || null">
            <i v-bind:class="getPercentArrow"></i> % {{ getPercent }}</b
          >
        </template>

        <i class="fas fa-minus" v-else></i>
      </p>
      <!-- ./Earning Rate -->

      <div class="row">
        <!-- Total Sale -->
        <div class="col-12 mb-2">
          <div class="d-block">
            <i class="fas fa-shopping-bag fa-lg icon-transparent"></i>
          </div>
          <div class="d-block mb-1">
            <span class="font-12 text-transparent-white-8">Satış Tutarı</span>
          </div>
          <h6 class="text-white font-14">
            {{ totalSalePrice | moneyFormat }}
          </h6>
        </div>
        <div class="col-12">
          <div class="d-block">
            <i class="fas fa-retweet fa-lg icon-transparent"></i>
          </div>
          <div class="d-block mb-1">
            <span class="font-12 text-transparent-white-8">İptal & İade</span>
          </div>
          <h6 class="text-white font-14">
            {{ totalRefund | moneyFormat }}
          </h6>
        </div>
      </div>
      <!-- Total Sale -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Item",
  data() {
    return {
      titles: [
        {
          name: "week",
          label: "Bu Hafta",
        },
        {
          name: "month",
          label: "Bu Ay",
        },
        {
          name: "six_month",
          label: "Son 6 Ay",
        },
        {
          name: "lifetime",
          label: "Tüm Zamanlar",
        },
      ],
    };
  },
  props: {
    data: null,
  },
  methods: {
    getReportTitle(name) {
      const title = this.titles.find((item) => item.name == name);
      if (title) return title.label;
    },
  },
  computed: {
    totalEarning() {
      return this.data.total_earning ? parseFloat(this.data.total_earning) : 0;
    },
    totalSalePrice() {
      return this.data.total_sale_price
        ? parseFloat(this.data.total_sale_price)
        : 0;
    },
    totalRefund() {
      return this.data.total_refund ? parseFloat(this.data.total_refund) : 0;
    },
    getPercent() {
      return Math.abs(this.data.percent);
    },
    getPercentArrow() {
      let result = "fas fa-caret-";

      if (this.data.percent < 0) {
        result += "down text-danger";
      } else {
        result += "up text-success";
      }

      return result;
    },
    showPercent() {
      return this.data.name !== "lifetime";
    },
  },
};
</script>
